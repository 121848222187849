$("#contato-form form").validate({
    rules: {
        name: {
            required: true
        },
        email: {
            required: true,
            email: true
        },
        phone: {
            required: true,
            celular: true
        },
        company: {
            minlength: 2
        }
    },
    submitHandler: function (form) {
        var $btn = $("#contato-form .button");
        $btn.attr('original-val', $btn.text());
        $btn.text('Carregando...');
        $btn.prop('disabled', true);

        var name = $("#name").val();
        var email = $("#email").val();
        var phone = $("#phone").val();
        var company = $("#company").val();
        var product_type = $("#product_type").val();

        if (name.length === 0 || email.length === 0 || phone.length === 0)
            return false;

        phone = phone.replace(/\D/g, '');
        if (phone.length > 11) phone = phone.substring(phone.length - 11);
        phone = phone.replace(/(\d{2})(\d{5}|\d{4})(\d{4})/, "($1) $2-$3");

        if (pipz) {
            pipz.identify(email, {
                name: name,
                email: email,
                phone: phone,
                product_type: product_type,
                event: {
                    "Clicked Button": {
                        button: "Submit Contact Form (Notas Fiscais)"
                    }
                }
            });
        };

        $.ajax({
            type: "POST",
            url: "https://us-central1-consulta-guru.cloudfunctions.net/send-leads-to-pubsub",
            data: JSON.stringify({ "source": "Notas Fiscais", "name": name, "email": email, "phone": phone, "product_type": product_type, "company": company }),
            contentType: "application/json",
        }).done(function () {
            dataLayer.push({ 'event': 'formSubmitted', 'formName': 'contato-nfe' });
            console.log("Cloud funcion called");
        });

        $.ajax({
            type: "POST",
            dataType: 'jsonp',
            url: "https://getsimpleform.com/messages/ajax?form_api_token=bed57a52b45d69be2af9d76189a2752d",
            data: {
                subject: "Nota fiscal",
                source: "Site",
                name: name,
                email: email,
                phone: phone,
                product_type: product_type
            }
        }).done(function () {
            $(form)[0].reset();
            $("#contato-form").html('<h5 style="text-align:center">Obrigado!<br/>Aguarde nossa equipe comercial entrará em contato em breve.</h5>');
            $btn.prop('disabled', false);
            $btn.text($btn.attr('original-val'));
        });
    }
});

$("#send-invoice-form form").validate({
    rules: {
        email: {
            required: true,
            email: true
        }
    },
    submitHandler: function (form) {
        var $btn = $("#send-invoice-form button");
        $btn.attr('original-val', $btn.text());
        $btn.text('Carregando...');
        $btn.prop('disabled', true);

        var email = $("#send-invoice-form #card-email").val();

        if (email.length === 0)
            return false;

        if (pipz) {
            pipz.identify(email, {
                email: email,
                event: {
                    "Clicked Button": {
                        button: "Send Test Invoice"
                    }
                }
            });
        };

        $.ajax({
            type: "POST",
            dataType: 'json',
            url: "https://us-central1-consulta-guru.cloudfunctions.net/send-leads-to-pubsub",
            contentType: "application/json",
            data: JSON.stringify({ "email": email, "source": "Site-invoice" })
        }).done(function () {
            dataLayer.push({ 'event': 'formSubmitted', 'formName': 'nota-teste' });
            $(form)[0].reset();
            $("#send-invoice-form form").toggleClass("card-form-success")
            $("#send-invoice-form .try-email-message").html('<h5 style="text-align:center">Acabamos de enviar para você!<br/>Dá uma olhadinha no seu email :)</h5>');
            $btn.prop('disabled', false);
            $btn.text($btn.attr('original-val'));
            $(".try-now .box-mail-content svg").addClass('stop');
        });
    }
});